.chat_wrapper{
    width: 100%;
    height: calc(100vh - 166px);
    font-family: 'Roboto', sans-serif;
}
.chat-layout .chat-box-wrapper {
    padding-top: 10px!important;
    padding-bottom: 10px!important;
}
.chat-box-wrapper {
    padding-top: 10px!important;
    padding-bottom: 10px!important;
}

.wrap-chat-main::-webkit-scrollbar {
    width: 5px;
  }
  
  .wrap-chat-main::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  .wrap-chat-main::-webkit-scrollbar-thumb {
    background: #888; 
  }

  .wrap-chat-main::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

 .group_members_div::-webkit-scrollbar {
  width: 5px;
}

.group_members_div::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
.group_members_div::-webkit-scrollbar-thumb {
  background: #888; 
}

.group_members_div::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.group_members::-webkit-scrollbar {
  width: 5px;
}

.group_members::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
.group_members::-webkit-scrollbar-thumb {
  background: #888; 
}

.group_members::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
  .unread_messages{
    width: 15px;
    background: #8db0ff;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    height: 15px;
    align-items: center;
    color: white;
    font-size: 10px;
  }

.app-inner-layout__bottom-pane{
  margin: 10px;
  box-sizing: border-box;
  padding: 15px 0px;
  border-top: 1px solid rgb(216 211 211);
}

.batch_div{
    width: 250px;
    height: auto;
    position: absolute;
    top: 64px;
    right: 0px;
    border: 1px solid #dcc9c9;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    padding: 10px 0px;
    box-sizing: border-box;
    opacity: 0;
    pointer-events: none;
    z-index: 1000;
    transform: translateY(-10);
    transition: transform 0.5s ease-out;
}
.batch_div.show{
  opacity: 1;
  pointer-events: all;
  transform: translateY(20);
  transition: transform 0.5s ease-out;
}

.batch_count{
  width: 20px;
  height: 20px;
  position: absolute;
  top: 21px;
  left: 0px;
  background: #e4e4e4;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat_people_list{
  display: block;
  width: 100%;
  padding: 0.4rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: rgb(33, 37, 41);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  transition: all .2s ease;
  border-bottom: 1px solid rgb(216 211 211);
}
.chat_people_list:hover{
  background-color: #cacaca;
}

.wrap_chat_height_style{
  height: calc(100vh - 265px);
  min-height: calc(100vh - 265px);
}

.people_scroll_div{
  list-style: none;
  overflow-y: scroll;
  height: calc(100vh - 205px);
  
}
.chat_listheader{
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  box-sizing: border-box;
  box-shadow: rgb(255 255 255 / 30%) 0px 1px 2px 0px, rgb(171 206 233 / 15%) 0px 1px 3px 1px;
}

.send_input{
  width: 100%;
  background:  rgba(229, 229, 229, 0.24);
  height: 35px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.send_input input{
  border: none;
  width: 90%;
  background-color: rgba(229, 229, 229, 0.24);
  margin-left: 5px;
  border-radius: 20px;
}
.send_input input:focus{
  outline: none !important;
}

.attachment_div{
  position: absolute;
    min-height: 188px;
    width: 40px;
    /* background-color: red; */
    right: 15px;
    top: -200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.attachment_item{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #F8FBFF;
  display: flex;
  align-items: center;
  justify-content: center;
  /* cursor: pointer; */
}
.attachment_item:hover{
  background-color: #bcbec1;
  cursor: pointer;
}
.attachment_div_hide{
  display: none;
}

.group_member_add{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #240777;
  display: flex;
  align-items: center;
  justify-content: center;
}
.group_members{
  width: 100%;
  max-height: 200px;
  word-wrap: break-word;
  padding: 0px 10px;
  height: 200px;
  margin-top: 20px;
  overflow-y: auto;
}
.recent_dropdown{
  width: 100px;
  height: 75px;
  background: #E5E5E5;
  position: absolute;
  right: 0px;
  top: 30px;
  opacity: 1;
  z-index: 1000;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}
.recent_dropdown-none{
    display: none;
}

.newGroup{
  color: black;
  cursor: pointer;
  font-weight: normal;
  margin-left: 10px;
}
.newChat{
  color: black;
  cursor: pointer;
  font-weight: normal;
  margin-left: 10px;

}
.newGroup:hover{
  font-weight: bold;
  transition: all .5s ease-in;
}
.newChat:hover{
  font-weight: bold;
  transition: all .5s ease-in;
}
/* ////////////////////////////////////// */
.group_member_name{
  position: absolute;
    top: -3px;
    left: 5px;
    z-index: 1000;
    font-weight: 600;
}

.remove_user{
  padding: 0px 10px;
  font-weight: 600;
  cursor: pointer;
}
.chat_role_icons{
  margin-left: 2px;
  font-size: 10px;
}

.hidden{
  display: none;
  
}
.video_size_div{
    position: absolute;
    right: 5px;
    top: 5px;
    background-color: #d3d3d3;
    width: 60px;
    height: 15px;
    border-radius: 20px;
    font-size: 11px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: black;
}
.video_processing_div{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f7f7f7;
}
.invitation_msg{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px 0px;
}
.invitation_msg div{
    background: #444444bf;
    color: white;
    padding: 2px 10px;
    border-radius: 10px;
    font-size: 13px;
}

.audio_player{
  height: 30px;
  width: 100%;
  margin-top: 5px;
}