.manual-left{
    width: 100%;
    height: 100vh;
    background-color: #7a2121;
    overflow-y: auto;
    color: white;
    box-sizing: border-box;
    padding: 10px;
    font-family: 'Montserrat', sans-serif;
}
.menu-right{
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 10px;
    word-wrap: break-word;
    background-color: white;
    font-size: 16px;
    color: black;
    line-height: 1.7;
    font-family: arial,x-locale-body,sans-serif;
}

.inner_li{
    margin-left: 10px;
    margin-top: 10px;
    cursor: pointer;
}
.burger-menu{
    display: none;
}
.button_group_mobile{
    display: none;
}
.image_div{
    width: 75%;
    height: 400px;
    margin: 15px 10px;
}

.image_div img{
    border: .5px solid rgb(97, 95, 95);
    border-radius: 10px;
}
.image_combined{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin: 15px 0px;
}
.image_combined1{
    width: 45%;
}
.image_combined2{
    width: 45%;
}
@media screen and (max-width: 960px) {
    .manual-left {
      height: auto;
      position: fixed;
    }
    .burger-menu{
        display: flex;
        cursor: pointer;
    }

    .menu-list-show{
        opacity: 1;
        height: auto;
        transition: height 0ms 0ms, opacity 600ms 0ms;
    }
    .menu-list-hide{
        opacity: 0;
        height: 0;
        overflow: hidden;
        transition: height 0ms 400ms, opacity 400ms 0ms;
    }
    
    .menu-right{
        height: auto;
        padding-top: 80px;
    }
    .button_group_mobile{
        display: block;
    }
    .button_group_list{
        display: none;
    }
  }
  
  @media screen and (max-width: 960px) {
    .image_div{
        width: 70%;
        height: 300px;
        margin: 15px 10px;
    }
}

@media screen and (max-width: 640px) {
    .image_div{
        width: 95%;
        height: 220px;
        margin: 15px 10px;
    }
    .image_combined{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        margin: 15px 0px;
    }
    .image_combined1{
        width: 100%;
    }
    .image_combined2{
        width: 100%;
        margin-top: 15px;
    }
}